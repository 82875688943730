import './3D.css';
import '../../StaticElement/Static.css';
import { useTranslation } from 'react-i18next';

export default function RestTour(props){
    const {t} = useTranslation();
    return(
        <div className='RestTour' id='3D'>
            <div className='RestTourCont'>
                <div className='RestTourBlockTwo'>
                    <div className='RestTourBlockTwoTitle'>
                        <div id={props.Link}>{t("Rest3D.Name").toUpperCase()}</div>
                        <hr color='Black' width='7%' style={{margin:'0px', border:'0.01px black solid'}}/>
                    </div>
                    <div className='RestTourBlockTwoBody'>{t("Rest3D.Title")}<br/>{t("Rest3D.Title2")}</div>
                    <div className='RestMenuShowButton' id={props.Link + 'Button'} onClick={()=> window.open(props.TourSource)}>{t("All.Watch3D")}</div>
                </div>
                <div className='TourPhoto' style={{background:`url(${props.TourImg}) 50% 50%/cover no-repeat`}}/>
            </div>
        </div>
    )
}