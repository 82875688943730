import './RestGalleryType.css';
import '../../../StaticElement/Static.css';
import RestGalleryPhotoCard from './RestGalleryPhotoCard';

export default function RestGalleryBar(props){
    return(
        <div className='RestGalleryBarCont'>
            <RestGalleryPhotoCard img={props.GalleryBar[0]} className='RestGalleryBarItem1' title='Яичница по-английски' text='(Моцарелла, лук, перец, чеснок, укроп, яйцо)'/>
            <RestGalleryPhotoCard img={props.GalleryBar[1]} className='RestGalleryBarItem2' title='Яичница по-английски' text='(Моцарелла, лук, перец, чеснок, укроп, яйцо)'/>
            <RestGalleryPhotoCard img={props.GalleryBar[2]} className='RestGalleryBarItem3' title='Яичница по-английски' text='(Моцарелла, лук, перец, чеснок, укроп, яйцо)'/>
            <RestGalleryPhotoCard img={props.GalleryBar[3]} className='RestGalleryBarItem4' title='Яичница по-английски' text='(Моцарелла, лук, перец, чеснок, укроп, яйцо)'/>
            <RestGalleryPhotoCard img={props.GalleryBar[4]} className='RestGalleryBarItem5' title='Яичница по-английски' text='(Моцарелла, лук, перец, чеснок, укроп, яйцо)'/>
            <RestGalleryPhotoCard img={props.GalleryBar[5]} className='RestGalleryBarItem6' title='Яичница по-английски' text='(Моцарелла, лук, перец, чеснок, укроп, яйцо)'/>
            <RestGalleryPhotoCard img={props.GalleryBar[6]} className='RestGalleryBarItem7' title='Яичница по-английски' text='(Моцарелла, лук, перец, чеснок, укроп, яйцо)'/>
            <RestGalleryPhotoCard img={props.GalleryBar[7]} className='RestGalleryBarItem8' title='Яичница по-английски' text='(Моцарелла, лук, перец, чеснок, укроп, яйцо)'/>
        </div>
    )
}