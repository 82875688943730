import './RestComment.css';
import '../../StaticElement/Static.css';
import { useRef, useEffect } from 'react';

export default function RestCommentRating(props){
    const StarRefs = useRef([])
    function StarEnter(e){
        for(let i = 0; i < 5; i++){
            StarRefs.current[i].className = `RestRatingStar${i + 1}`;
        }
        if(e.target.className.indexOf('RestRatingStar--Active') === -1){
            e.target.classList.toggle('RestRatingStar--Active');
            for(let i = 0; i < parseInt(e.target.className.match(/\d+/g)[0]) - 1; i++){
                if(StarRefs.current[i].className.indexOf('RestRatingStar--Active') === -1) StarRefs.current[i].classList.toggle('RestRatingStar--Active');
            }
        }
    }
    useEffect(() => {
        if(!props.display){
            for(let i = 0; i < 5; i++){
                StarRefs.current[i].className = `RestRatingStar${i + 1}`;
            }
        }
    }, [StarRefs, props.display]);
    function StarLeave(e){
        for(let i = 5; i > props.Rating; i--){
            StarRefs.current[i-1].className = `RestRatingStar${i}`;
        }      
        for(let i = 0; i < props.Rating; i++){
            StarRefs.current[i].className = `RestRatingStar${i+1} RestRatingStar--Active`;
        }  
    }

    function Click(e){
        props.setRating(parseInt(e.target.getAttribute('value')));
    }
    
    return(
        <div className='RestCommentCardRatingStar RestCommentCardRatingStarModal'>
            <div ref={el => StarRefs.current[0] = el} className='RestRatingStar1' onMouseEnter={StarEnter} onMouseLeave={StarLeave} onClick={Click} value='1'/>
            <div ref={el => StarRefs.current[1] = el} className='RestRatingStar2' onMouseEnter={StarEnter} onMouseLeave={StarLeave} onClick={Click} value='2'/>
            <div ref={el => StarRefs.current[2] = el} className='RestRatingStar3' onMouseEnter={StarEnter} onMouseLeave={StarLeave} onClick={Click} value='3'/>
            <div ref={el => StarRefs.current[3] = el} className='RestRatingStar4' onMouseEnter={StarEnter} onMouseLeave={StarLeave} onClick={Click} value='4'/>
            <div ref={el => StarRefs.current[4] = el} className='RestRatingStar5' onMouseEnter={StarEnter} onMouseLeave={StarLeave} onClick={Click} value='5'/>
        </div>
    )
}