import '../StaticElement/Static.css';
import MenuEatBody from './MenuEatBody/MenuEatBody';
import MenuEatHeader from './MenuEatHeader/MenuEatHeader';
import { NrgOficialLogoBlack } from '../StaticElement/Component/Icon/Icon';
import { useEffect } from 'react';
import { useLocation, useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

export default function MenuEat(){
    const { pathname } = useLocation();
    const {t, i18n} = useTranslation();
    const params = useParams();

    useEffect(() => {
        window.scrollTo(0, 0);
    }, [pathname]);
    useEffect(()=>{
        i18n.changeLanguage(params.lang.toLowerCase());
    },[i18n, params.lang])

    return(
        <div>
            <MenuEatHeader/>
            <MenuEatBody/>
            <div className='FooterCont'>
                <div className='FooterLogo'>
                    <NrgOficialLogoBlack/>
                    <div style={{borderBottom:'1px black solid', paddingBottom:'3px', cursor:'pointer'}} onClick={() => window.open('http://sncgroup.by/', '_blank')}>{t("All.WebsiteDev")}</div>
                </div>
            </div>
        </div>
    )
}