import './RestContact.css';
import '../../StaticElement/Static.css';
import { useTranslation } from 'react-i18next';

export default function RestContact(props){
    const {t} = useTranslation();
    return(
        <div className='RestContactCont' id='Contacts'>
            <div className='RestContact'>
                <div className='RestContactTitle'>{t("All.Loc")}</div>
                <div className='RestContactText'>{t(`RestTemplate.${props.Link}.Adress`)}</div>
                <div className='RestContactTitle'>{t("All.TimeWork")}</div>
                <div className='RestContactText'>{t(`RestTemplate.${props.Link}.Time`)}</div>
                <div className='RestContactTitle'>{t("Menu.contact")}</div>
                {props.Mail === false ? '' : <div className='RestContactText'>Email: {props.ContactMail}</div>}
                <a href={'tel:' + props.ContactTel} className='RestContactText'>{t("All.Tel")}: {props.ContactTel}</a>
                <a href={'tel:' + props.ContactTel} className='RestContactBron'>{t("All.BronTable")}</a>
            </div>
            <iframe src={props.ContactMap} className='RestContactMap' frameBorder="0" title='Map'></iframe>
        </div>
    )
}