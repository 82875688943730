import './RestHeader.css';
import { useRef, useEffect, useState } from 'react';

export default function RestPhotoSliderButtons(props){
    let Z1Button = useRef(),
        Z2Button = useRef(),
        Z3Button = useRef();
    const [ZActiveButton, setZActiveButton] = useState(Z1Button);

    useEffect(() => {
        let interval = setInterval(()=>{
            Move(props.Stage);
        }, 5000)
        return () => clearInterval(interval);
    });

    function Move(st){
        switch(st){
            case 1:
                ZActiveButton.current.classList.toggle('CubeButton--Active');
                Z1Button.current.classList.toggle('CubeButton--Active');
                setZActiveButton(Z1Button);
                break;
            case 2:
                ZActiveButton.current.classList.toggle('CubeButton--Active');
                Z2Button.current.classList.toggle('CubeButton--Active');
                setZActiveButton(Z2Button);
                break;
            case 3:
                ZActiveButton.current.classList.toggle('CubeButton--Active');
                Z3Button.current.classList.toggle('CubeButton--Active');
                setZActiveButton(Z3Button);
                break;
            default:
                break;
        }
    }

    function Change(e){
        ZActiveButton.current.classList.toggle('CubeButton--Active');
        e.target.classList.toggle('CubeButton--Active');
        switch (parseInt(e.target.getAttribute('value'))) {
            case 1:
                setZActiveButton(Z1Button);
                break;
            case 2:
                setZActiveButton(Z2Button);
                break;
            case 3:
                setZActiveButton(Z3Button);
                break;
            default:
                break;
        }
        props.setStage(parseInt(e.target.getAttribute('value')));
        props.Slider(parseInt(e.target.getAttribute('value')));
    }

    return(
        <div className='CubeButtonCont'>
            <div ref={Z1Button} className='CubeButton CubeButton--Active' value='1' onClick={Change}/>
            <div ref={Z2Button} className='CubeButton' value='2' onClick={Change}/>
            <div ref={Z3Button} className='CubeButton' value='3' onClick={Change}/>
        </div>
    )
}