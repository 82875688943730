import './RestMenu.css';
import '../../StaticElement/Static.css';
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

export default function RestMenu(props){
    const { t, i18n } = useTranslation();
    return(
        <div className='RestMenuCont' id='Menu'>
            <img src={props.MenuPhoto} alt='MenuPhoto' className='MenuPhoto'/>
            <div className='RestMenuBlockTwo'>
                <div className='RestMenuBlockTwoTitle'>
                    <div id={props.Link}>{t("Menu.menu").toUpperCase()}</div>
                    <hr color='Black' width='30%' style={{margin:'0px', border:'0.01px black solid'}}/>
                </div>
                <div className='RestMenuBlockTwoBody'>{t(`RestTemplate.${props.Link}.MenuText`)}</div>
                <Link to={`/Menu${i18n.language.toUpperCase() === 'RU' ? 'RU' : 'EN'}`}><div className='RestMenuShowButton' id={props.Link + 'Button'}>{t("All.WatchMenu")}</div></Link>
            </div>
        </div>
    )
}