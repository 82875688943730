import './RestComment.css';
import '../../StaticElement/Static.css';
import RestCommentRating from './RestCommentRating';
import { useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import axios from 'axios';

export default function RestComment(props){
    const {t} = useTranslation();
    const [Kitchen, setKitchen] = useState(0);
    const [Comfort, setComfort] = useState(0);
    const [Service, setService] = useState(0);
    const [Comment, setComment] = useState({Name:'', Phone:'', Mail:'', Comment:''});
    const ErrorInput = useRef();
    
    function SendComment(e){
        e.preventDefault();
        if(Comment.Name.trim().length === 0 || Comment.Phone.trim().length === 0 || Comment.Mail.trim().length === 0 || Comment.Comment.trim().length === 0 || Kitchen === 0 || Comfort === 0 || Service === 0 || Comment.Phone.match(/^((\+375|375)+(29|33|44|25)+([0-9]){7})$/) === null || Comment.Mail.match(/^[-\w.]+@([A-z0-9][-A-z0-9]+\.)+[A-z]{2,4}$/) === null ){
            if(ErrorInput.current.className.indexOf('inputError') === -1) ErrorInput.current.classList.toggle('inputError');
            return;
        }
        else{
            let idRest;
            switch (props.Link.toLowerCase()) {
                case 'theview':
                    idRest = 1;
                    break;
                case 'catering':
                    idRest = 2;
                    break;
                case 'tuse':
                    idRest = 3;
                    break;
                case 'poedempoedim':
                    idRest = 4;
                    break;
                default:
                    break;
            }
            axios.post('http://admin.nrg.by/api/v1/reviews/create', {
                customer_email: Comment.Mail,
                customer_name: Comment.Name,
                customer_rating_1: Kitchen,
                customer_rating_2: Comfort,
                customer_rating_3: Service,
                restaurant_front_end_key: idRest,
                customer_text: Comment.Comment
              })
              .then(function (response) {
                if(ErrorInput.current.className.indexOf('inputOk') === -1) ErrorInput.current.classList.toggle('inputOk');
              })
              .catch(function (error) {
                if(ErrorInput.current.className.indexOf('inputError') === -1) ErrorInput.current.classList.toggle('inputError');
              });
        }
    }

    return(
        <div className='RestCommentCont' id='Comment'>
            <div className='RestCommentTitle'>
                <div id={props.Link}>{t("RestComment.Name").toUpperCase()}</div>
                <hr color='Black' width='30%' style={{margin:'0px', border:'0.01px black solid', marginTop:'1vw'}}/>
            </div>
            <div className='RestComment'>
                <form name='CommentForm' id='form'>
                    <div className='RestCommentFormCont'>
                        <input name='Name' required type='text' placeholder={t("RestComment.PerName")} value={Comment.Name} onChange={(e) => setComment({...Comment, Name:e.target.value})}/>
                        <input name='Tel' required type='text' placeholder={t("RestComment.PerTel")} value={Comment.Phone} onChange={(e) => setComment({...Comment, Phone:e.target.value})}/>
                        <input name='email' required type='email' placeholder='E-Mail*' value={Comment.Mail} onChange={(e) => setComment({...Comment, Mail:e.target.value})}/>
                        <textarea name='Comment' required placeholder={t("RestComment.TextComment")} value={Comment.Comment} rows={5} onChange={(e) => setComment({...Comment, Comment:e.target.value})}/>
                        <div className='RestCommentCardRatingElCont RestCommentCardRatingElContModal'>
                            <div>{t("Menu.Kitchen")}</div>
                            <RestCommentRating Rating={Kitchen} setRating={setKitchen} display={props.display}/>
                        </div>
                        <div className='RestCommentCardRatingElCont RestCommentCardRatingElContModal'>
                            <div>{t("RestComment.Comfort")}</div>
                            <RestCommentRating Rating={Comfort} setRating={setComfort} display={props.display}/>
                        </div>
                        <div className='RestCommentCardRatingElCont RestCommentCardRatingElContModal'>
                            <div>{t("RestComment.Service")}</div>
                            <RestCommentRating Rating={Service} setRating={setService} display={props.display}/>
                        </div>
                    </div>
                    <div className='RestCommentFormContTwo'>
                        <div>{t("RestComment.Title")}</div>
                        <button ref={ErrorInput} className='RestMenuShowButton' id={props.Link + "Button"} onClick={SendComment}>{t("RestComment.SendMail")}</button>
                    </div>
                </form>
            </div>
        </div>
    )
}