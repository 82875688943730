import './RestGallery.css';
import '../../StaticElement/Static.css';
import { useRef, useState } from 'react';
import RestGalleryKitchen from './RestGalleryTypeComponent/RestGalleryKitchen';
import RestGalleryInterior from './RestGalleryTypeComponent/RestGalleryInterior';
import RestGalleryBar from './RestGalleryTypeComponent/RestGalleryBar';
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

export default function RestGallery(props){
    const {t, i18n} = useTranslation();
    const [NavActiveId, setNavActiveId] = useState(1);
    let NavActive = useRef();

    function Change(e){
        NavActive.current.classList.toggle('RestGalleryNavEl--Active');
        NavActive.current = e.target;
        NavActive.current.classList.toggle('RestGalleryNavEl--Active');
        setNavActiveId(parseInt(NavActive.current.getAttribute('value')));
    }

    function RenderGallery(id){
        switch (id) {
            case 1:
                return <RestGalleryInterior GalleryInterior={props.GalleryInterior} Link={props.Link}/>;
            case 2:
                return <RestGalleryKitchen GalleryKitchen={props.GalleryKitchen} GalleryKitchenName={props.GalleryKitchenName} Link={props.Link}/>;
            case 3:
                return <RestGalleryBar GalleryBar={props.GalleryBar} Link={props.Link}/>;
            default:
                break;
        }
    }
    return(
        <div className='RestGalleryCont' id='Gallery'>
            <div className='RestGalleryTitle'>
                <div id={props.Link}>{t("Menu.gallery").toUpperCase()}</div>
                <hr color='Black' width='30%' style={{margin:'0px', border:'0.01px black solid', marginTop:'1vw'}}/>
            </div>
            <div className='RestGalleryNavCont'>
                <div ref={NavActive} className='RestGalleryNavEl RestGalleryNavEl--Active' onClick={Change} value='1'>{t("Menu.Interior")}</div>
                <div className='RestGalleryNavEl' onClick={Change} value='2'>{t("Menu.Kitchen")}</div>
                {props.Bar ? <div className='RestGalleryNavEl' onClick={Change} value='3'>{t("Menu.Bar")}</div> : ''}
            </div>
            {RenderGallery(NavActiveId)}
            <Link to={`/Gallery${i18n.language.toUpperCase() === 'RU' ? 'RU' : 'EN'}`}><div className='RestMenuShowButton' id={props.Link + 'Button'}>{t("All.AllPhoto")}</div></Link>
        </div>
    )
}