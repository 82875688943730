import React from 'react';
import { createRoot } from 'react-dom/client';
import App from './App/App';
import { BrowserRouter } from 'react-router-dom';
import './i18n';

const container = document.getElementById('root');
const root = createRoot(container); 

root.render(
  <BrowserRouter>
    <App/>
  </BrowserRouter>
);

