import '../StaticElement/Static.css';
import GalleryFullBody from './GalleryFullBody/GalleryFullBody';
import GalleryFullHeader from './GalleryFullHeader/GalleryFullHeader';
import { NrgOficialLogoBlack } from '../StaticElement/Component/Icon/Icon';
import { useEffect } from 'react'
import { useLocation, useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

export default function GalleryFull(){
    const { pathname } = useLocation();
    const {t, i18n} = useTranslation();
    const params = useParams();
    
    useEffect(() => {
        window.scrollTo(0, 0);
    },[pathname]);
    
    useEffect(()=>{
        i18n.changeLanguage(params.lang.toLowerCase());
    },[i18n, params.lang])

    return(
        <div>
            <GalleryFullHeader/>
            <GalleryFullBody/>
            <div className='FooterCont'>
                <div className='FooterLogo'>
                    <NrgOficialLogoBlack/>
                    <div style={{borderBottom:'1px black solid', paddingBottom:'3px', cursor:'pointer'}} onClick={() => window.open('http://sncgroup.by/', '_blank')}>{t("All.WebsiteDev")}</div>
                </div>
            </div>
        </div>
    )
}