import { useRef } from 'react';
import './CustomSelect.css';
import '../../Static.css';
import { useTranslation } from "react-i18next";
import { useNavigate } from 'react-router-dom';

export default function CustomSelect(props){
    const { i18n } = useTranslation();
    let select__current = useRef();
    let select = useRef();
    let navigate = useNavigate();

    return(
        <div ref={select} className="select">
            <label onClick={() => select.current.classList.toggle('is-active')}>
                <div className="select__header" >
                    <div ref={select__current} className="select__current">{i18n.language.toUpperCase()}</div>
                </div>
            </label>
            <div className="select__body">
                <div className="select__item" onClick={()=>{
                    select.current.classList.toggle('is-active');
                    navigate(`/${i18n.language.toUpperCase() === 'RU' ? 'EN' : 'RU'}`);
                }}>{i18n.language === 'ru' ? 'EN' : 'RU'}</div>
            </div>
        </div>
    )
}