import Footer from "./Footer/Footer";
import RestTour from "./Rest3D/RestTour";
import RestComment from "./RestComment/RestComment";
import RestContact from "./RestContact/RestContact";
import RestGallery from "./RestGallery/RestGallery";
import RestHeader from "./RestHeader/RestHeader";
import RestMenu from "./RestMenu/RestMenu";
import RestTextOne from "./RestTextOne/RestTextOne";
import './RestTemplate.css';
import { useEffect } from 'react';
import { useLocation, useParams } from "react-router-dom";
import { useTranslation } from "react-i18next";

export default function RestTemplate(props){
    const { pathname } = useLocation();
    const params = useParams();
    const {t, i18n} = useTranslation();

    useEffect(() => {
        window.scrollTo(0, 0);
    }, [pathname]);
    useEffect(()=>{
        i18n.changeLanguage(params.lang.toLowerCase());
    },[i18n, params.lang])
    return(
        <div style={{background:'#EBEBEB', overflow:'hidden'}}>
            <RestHeader 
                Logo={props.Logo}
                HeaderImgArr={props.HeaderImgArr}
                Link={props.Link}
                ContactTel={props.ContactTel}
                Inst={props.Inst}
                setLoaded={props.setLoaded}
                Menu={props.Menu}
                TourSource={props.TourSource}
                Relax={props.Relax}
                Tour={props.Tour}
                Telegram={props.Telegram}
            />
            <RestTextOne Link={props.Link}/>
            {props.Menu ? <RestMenu MenuPhoto={props.MenuPhoto} Link={props.Link} /> : ''}
            <RestGallery 
                GalleryKitchen={props.GalleryKitchen}
                GalleryKitchenName={props.GalleryKitchenName}
                GalleryInterior={props.GalleryInterior}
                GalleryBar={props.GalleryBar}
                Link={props.Link}
                Bar={props.Bar}
            />
            <RestComment Name={props.Name} Link={props.Link}/>
            {props.Tour ? <RestTour Link={props.Link} TourImg={props.TourImg} TourSource={props.TourSource}/> : ''}
            <RestContact
                ContactMap={props.ContactMap}
                ContactTime={props.ContactTime}
                ContactLoc={props.ContactLoc}
                ContactMail={props.ContactMail}
                ContactTel={props.ContactTel}
                Link={props.Link}
                Mail={props.Mail}
            />
            <Footer links={[{link:'About', text:t("Menu.about")}, props.Menu ? {link:'Menu', text:t("Menu.menu")} : {link:'', text:''}, {link:'Gallery', text:t("Menu.gallery")}, {link:'Comment', text:t("Menu.comment")}, props.Tour ? {link:'3D', text:t("Menu.3D")} : {link:'', text:''}, {link:'Contacts', text:t("Menu.contact")}]}/>
        </div>
    )
}