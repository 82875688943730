import './Footer.css';
import '../../StaticElement/Static.css';
import Scroll from 'react-scroll';
import { useTranslation } from 'react-i18next'; 
import { NrgOficialLogoBlack } from '../../StaticElement/Component/Icon/Icon';
import { Link } from 'react-router-dom';

export default function Footer(props){
    const { t } = useTranslation();
    return(
        <div className='FooterCont'>
            <div className='FooterMenu'>
                <div>{t("Menu.menu")}</div>
                {props.links.map((item, i) => {
                    if(item.link === '') return '';
                    else if(item.value === undefined)
                        return <Scroll.Link key={i} to={item.link} spy={true} smooth={true} duration={500}>{item.text}</Scroll.Link>;
                    else if (item.value !== 'Home')
                        return <Scroll.Link key={i} to={item.link} spy={true} smooth={true} duration={500}><div onClick={props.onClick} value={item.value}>{item.text}</div></Scroll.Link>;
                    else
                        return <Link to={'/' + item.href}><div>{item.text}</div></Link>
                })}
            </div>
            <div className='FooterLogo'>
                <NrgOficialLogoBlack/>
                <div style={{borderBottom:'1px black solid', paddingBottom:'3px', cursor:'pointer'}} onClick={() => window.open('http://sncgroup.by/', '_blank')}>{t("All.WebsiteDev")}</div>
            </div>
        </div>
    )
}