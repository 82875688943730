import './MenuEatBody.css';
import '../../StaticElement/Static.css';

export default function EatEl(props){
    return(
        <div className='EatElCont'>
            <div className='EatElBlockCont'>
                <div className='EatElName'>{props.Name}</div>
                <div className='EatElDisc'>{props.Disc}</div>
            </div>
            <div className='EatElId'>{props.Id}</div>
        </div>
    )
}