import './MenuEatBody.css';
import '../../StaticElement/Static.css';
import EatEl from './EatEl';
import i18next from 'i18next';

export default function MenuEatCardTwo(props){
    return(
        <div className='MenuEatCardTwoCont'>
            <div className='MenuEatCardOneCont1'>
                <div className='TitleEat'>{props.Name}</div>
                {props.cards.map((item, i) => <EatEl key={item.id} Name={i18next.language === 'ru' ? item.name_ru : item.name_en} Disc={i18next.language === 'ru' ? item.description_ru : item.description_en} Id={item.price}/> )}

            </div>
        </div>
    )
}