import './RestGalleryType.css';
import '../../../StaticElement/Static.css';
import RestGalleryPhotoCard from './RestGalleryPhotoCard';
import { useTranslation } from 'react-i18next';

export default function RestGalleryKitchen(props){
    const {t} = useTranslation();
    return(
        <div className='RestGalleryKitchenCont'>
            <RestGalleryPhotoCard img={props.GalleryKitchen[0]} className='RestGalleryKitchenItem1' title={t(`RestTemplate.${props.Link}.GalleryKitchenName.One`)} text={t("All.InfPhoto")} inv={true}/>
            <RestGalleryPhotoCard img={props.GalleryKitchen[1]} className='RestGalleryKitchenItem2' title={t(`RestTemplate.${props.Link}.GalleryKitchenName.Two`)} text={t("All.InfPhoto")} inv={true}/>
            <img src={props.GalleryKitchen[2]} alt='KitchenLogo' className='RestGalleryKitchenItem3'/>
            <RestGalleryPhotoCard img={props.GalleryKitchen[3]} className='RestGalleryKitchenItem4' title={t(`RestTemplate.${props.Link}.GalleryKitchenName.Three`)} text={t("All.InfPhoto")} inv={true}/>
            <RestGalleryPhotoCard img={props.GalleryKitchen[4]} className='RestGalleryKitchenItem5' title={t(`RestTemplate.${props.Link}.GalleryKitchenName.Four`)} text={t("All.InfPhoto")} inv={true}/>
            <RestGalleryPhotoCard img={props.GalleryKitchen[5]} className='RestGalleryKitchenItem6' title={t(`RestTemplate.${props.Link}.GalleryKitchenName.Five`)} text={t("All.InfPhoto")} inv={true}/>
        </div>
    )
}