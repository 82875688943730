import './RestGalleryType.css';
import '../../../StaticElement/Static.css';
import RestGalleryPhotoCard from './RestGalleryPhotoCard';

export default function RestGalleryInterior(props){
    return(
        <div className='RestGalleryInteriorCont'>
            <RestGalleryPhotoCard img={props.GalleryInterior[0]} className='RestGalleryInteriorItem1' title='Яичница по-английски' text='(Моцарелла, лук, перец, чеснок, укроп, яйцо)'/>
            <RestGalleryPhotoCard img={props.GalleryInterior[1]} className='RestGalleryInteriorItem2' title='Яичница по-английски' text='(Моцарелла, лук, перец, чеснок, укроп, яйцо)'/>
            <RestGalleryPhotoCard img={props.GalleryInterior[2]} className='RestGalleryInteriorItem3' title='Яичница по-английски' text='(Моцарелла, лук, перец, чеснок, укроп, яйцо)'/>
            <RestGalleryPhotoCard img={props.GalleryInterior[3]} className='RestGalleryInteriorItem4' title='Яичница по-английски' text='(Моцарелла, лук, перец, чеснок, укроп, яйцо)'/>
            <RestGalleryPhotoCard img={props.GalleryInterior[4]} className='RestGalleryInteriorItem5' title='Яичница по-английски' text='(Моцарелла, лук, перец, чеснок, укроп, яйцо)'/>
            <img src={props.GalleryInterior[5]} alt='InteriorLogo' className='RestGalleryInteriorItem6'/>
            <RestGalleryPhotoCard img={props.GalleryInterior[6]} className='RestGalleryInteriorItem7' title='Яичница по-английски' text='(Моцарелла, лук, перец, чеснок, укроп, яйцо)'/>
        </div>
    )
}