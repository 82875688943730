import './RestGalleryType.css';
import '../../../StaticElement/Static.css';

export default function RestGalleryPhotoCard(props){
    return(
        <div className={'RestPhotoCardCont ' + props.className} id={props.inv === true ? 'inv' : ''} style={{width:props.width, height:props.height}}>
            <div className='RestPhotoCardPhoto' style={{background:`url(${props.img}) 50% 50%/cover no-repeat`, width:'100%', height:'100%'}}/>
            {props.inv === true ? 
                <div className='RestPhotoCardTextCont'>
                <div className='RestPhotoCardTextTitle'>{props.title}</div>
                <hr color='white' width='40%'/>
                <div className='RestPhotoCardText'>{props.text}</div>
            </div>
            :
            null}
        </div>
    )
}