import '../../StaticElement/Static.css';
import { Link } from 'react-router-dom';
import { useEffect, useState } from 'react'
import { Arrow, Instagram, TuseLogo } from '../../StaticElement/Component/Icon/Icon';
import { useTranslation } from 'react-i18next';

export default function GalleryFullHeader(){
    const { t, i18n } = useTranslation();
    const [LinkInst, setLinkInst] = useState('');
    const [Bron, setBron] = useState('');
    const [Logo, setLogo] = useState();
    const [Rest, setRest] = useState({});
    useEffect(() => {
        setLinkInst('https://www.instagram.com/tuse_minsk/?igshid=YmMyMTA2M2Y%3D');
        setBron('+375 29 105 6666');
        setLogo(<TuseLogo fill='black'/>);
        setRest({imgBack:require('../../StaticElement/Image/Menu/MenuTuse.webp')})
    }, []);

    return(
        <div className='MenuEatHeaderCont'>
            <div className='MenuEatHeaderTop'>
                {Logo}
                <div className='MenuEatHeaderTopButton'>
                    <Link to={'/' + (i18n.language === 'ru' ? 'RU' : 'EN')}><div className='MenuEatHome'>{t("All.ToHome")}</div></Link>
                    <Instagram color='black' onClick={()=>window.open(LinkInst, '_blank')}/>
                </div>
            </div>
            <div className='MenuEatHeaderBody' style={{background:`url('${Rest.imgBack}') no-repeat center`}}>
                <div className='MenuEatHeaderBodyTitle'>{t("Menu.gallery").toUpperCase()}</div>
            </div>
        </div>
    )
}