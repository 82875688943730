import './RestTextOne.css';
import '../../StaticElement/Static.css';
import { useTranslation } from 'react-i18next';

export default function RestTextOne(props){
    const { t } = useTranslation();
    return(
        <div className='RestTextOneCont' id='About'>
            <div className='RestTextOneText'>{t(`RestTemplate.${props.Link}.TextOne`)}</div>
        </div>
    )
}