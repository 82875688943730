import i18n from 'i18next'
import Backend from 'i18next-http-backend'
import LanguageDetector from 'i18next-browser-languagedetector'
import { initReactI18next } from 'react-i18next'
import translationRU from './StaticElement/Lang/RU.json';
import translationEN from './StaticElement/Lang/EN.json';

const resources = {
    en: {
      translation: translationEN
    },
    ru: {
      translation: translationRU
    }
  };

i18n.use(Backend)
    .use(LanguageDetector)
    .use (initReactI18next)
    .init({
        resources,
        lng:"ru",
        debug: false,
        detection: {
            order: ['localStorage', 'cookie'],
            caches: ['localStorage', 'cookie']
        },
        interpolation: {
            escapeValue: false
        }
    })

export default i18n;