import { Navigate, Route, Routes } from 'react-router-dom';
import GalleryFull from '../GalleryFull/GalleryFull';
import MenuEat from '../MenuEat/MenuEat';
import RestTemplate from '../RestTemplate/RestTemplate';
import './App.css';

export default function App() {
  return (
    <Routes>
      <Route path='/' element={<Navigate to='/RU'/>}/>
      <Route path='/:lang' element={
        <RestTemplate
          HeaderImgArr={[require('../StaticElement/Image/Tuse/1.webp'), require('../StaticElement/Image/Tuse/2.webp'),require('../StaticElement/Image/Tuse/3.webp')]}
          MenuPhoto={require('../StaticElement/Image/Tuse/MenuBlock.webp')}
          GalleryKitchen={[require('../StaticElement/Image/Tuse/Kitchen_1.webp'), require('../StaticElement/Image/Tuse/Kitchen_2.webp'), require('../StaticElement/Image/Tuse/TUSELogo.png'), require('../StaticElement/Image/Tuse/Kitchen_3.webp'), require('../StaticElement/Image/Tuse/Kitchen_4.webp'), require('../StaticElement/Image/Tuse/Kitchen_5.webp')]}
          GalleryInterior={[require('../StaticElement/Image/Tuse/Interior_1.webp'), require('../StaticElement/Image/Tuse/Interior_2.webp'), require('../StaticElement/Image/Tuse/Interior_3.webp'), require('../StaticElement/Image/Tuse/Interior_4.webp'), require('../StaticElement/Image/Tuse/Interior_5.webp'), require('../StaticElement/Image/Tuse/TUSE.png'), require('../StaticElement/Image/Tuse/Interior_6.webp')]}
          ContactMap='https://yandex.ru/map-widget/v1/?um=constructor%3A55866e0876a15cffa1f5d3aac59b13ac63dfd0f4f6fc7d037caec74d464c1e89&amp;source=constructor'
          ContactMail='tuse@nrg.by'
          ContactTel='+375 44 709 2222'
          Inst='https://instagram.com/tuse_minsk?igshid=YmMyMTA2M2Y='
          Link='Tuse'
          Tour={true}
          Menu={true}
          Bar={false}
          Telegram='https://t.me/tuse_minsk_bot'
          TourImg={require('../StaticElement/Image/Tuse/tourimg.jpg')}
          TourSource='https://yandex.by/maps/org/tuse/33880200700/?l=stv%2Csta&ll=27.582703%2C53.928742&panorama%5Bdirection%5D=201.565074%2C-9.864142&panorama%5Bfull%5D=true&panorama%5Bpoint%5D=27.582677%2C53.928784&panorama%5Bspan%5D=115.679057%2C60.000000&z=18.98'
          Relax='https://tuse.relax.by/'
        />
      }/>
      <Route path='/Menu:lang' element={<MenuEat/>}/>
      <Route path='/Gallery:lang' element={<GalleryFull/>}/>
    </Routes>
  );
}